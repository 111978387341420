import React, { useEffect, useState } from "react";
import RevealAnimation from "../RevealAnimation";
import "./style.scss";

import Arup from "../../assets/Images/Homepage/HeroSection/arup.png";
import Cb from "../../assets/Images/Homepage/HeroSection/cb.png";
import Cern from "../../assets/Images/Homepage/HeroSection/cern.png";
import Channel from "../../assets/Images/Homepage/HeroSection/channel.png";
import CVCImage from "../../assets/Images/Homepage/HeroSection/cvc.png";
import DentalCare from "../../assets/Images/Homepage/HeroSection/dentalCare.png";
import Network from "../../assets/Images/Homepage/HeroSection/networkPlus.png";
import Nhs from "../../assets/Images/Homepage/HeroSection/nhs.png";

import CernLogo from "../../assets/Images/Homepage/HeroSection/cern-logo.png";
import ArupLogo from "../../assets/Images/Homepage/HeroSection/arup-logo.png";
import ChannelLogo from "../../assets/Images/Homepage/HeroSection/channel-logo.png";
import NetworkLogo from "../../assets/Images/Homepage/HeroSection/network-plus.png";
import NhsLogo from "../../assets/Images/Homepage/HeroSection/nhs-logo.png";

function NewHeroSection() {
  const [tagsKey, setTagKey] = useState({
    key: "data",
    image: Arup,
  });

  let ItemsKey = [
    {
      key: "data",
      image: Arup,
    },
    {
      key: "UX",
      image: Cb,
    },
    {
      key: "GenAi",
      image: Cern,
    },
    {
      key: "apps",
      image: DentalCare,
    },
    {
      key: "websites",
      image: CVCImage,
    },
    {
      key: "Saas",
      image: Nhs,
    },
    {
      key: "Platforms",
      image: Channel,
    },
    {
      key: "agile teams",
      image: Network,
    },
  ];

  useEffect(() => {
    let index = 0; // Starting index
    const intervalId = setInterval(() => {
      setTagKey(ItemsKey[index]);
      index = (index + 1) % ItemsKey.length;
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bannerHeroHome">
      <div className="heroSectionWrap body-container">
        <div className="leftSectionWrapper">
          <RevealAnimation component={"h2"} className="title">
            Build Your Digital Products, <br />
            SaaS Solutions and <br />
            Tech Capabilities <br />
          </RevealAnimation>
          <RevealAnimation component={"h4"} className="subTitle">
            Developing software together, we can bridge the digital skills gap
            and accelerate your digital ambitions.
          </RevealAnimation>
          <div className="cardDetails">
            <RevealAnimation
              component={"h2"}
              className="cardHeading"
              animation="title"
            >
              Build Ryte
            </RevealAnimation>
            <div className="cardBox">
              <h3 className="changeText">{tagsKey?.key}</h3>
              <div className="blinking"></div>
            </div>
          </div>
        </div>
        <RevealAnimation animation="zoom-in" className="rightSectionWrapper">
          {tagsKey?.image && (
            <img className={"hero-image"} src={tagsKey?.image} alt={""} />
          )}
        </RevealAnimation>
      </div>
      <div className="image-channel body-container">
        {[
          { image: NhsLogo },
          { image: ArupLogo },
          { image: ChannelLogo },
          { image: CernLogo },
          { image: NetworkLogo },
        ].map((item, keys) => (
          <div key={keys}>
            <img className={"hero-image"} src={item?.image} alt={""} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewHeroSection;
