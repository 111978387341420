import React, { useEffect, useState } from "react";
import "./style.scss";
import FirstImg from "../../assets/Images/Homepage/OurModel/1.png";
import SecondImg from "../../assets/Images/Homepage/OurModel/2.png";
import ThirdImg from "../../assets/Images/Homepage/OurModel/3.png";

import FirstImgHover from "../../assets/Images/Homepage/OurModel/1-hover.png";
import SecondImgHover from "../../assets/Images/Homepage/OurModel/2-hover.png";
import ThirdImgHover from "../../assets/Images/Homepage/OurModel/3-hover.png";

import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

import { Link } from "@reach/router";
import ProgressBar from "../ProgressBar";
import RevealAnimation from "../RevealAnimation";

const OurModelSection = ({ wrapClassName = "" }) => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const listObject = [
    {
      imageUrl: FirstImg,
      imageUrlHover: FirstImgHover,
      step: 1,
      title: "We build your team",
      subtitle:
        "We build your tech team from the top 3% of UK and India`s renowned talent pool with former Fortune 500 and Big 4 experience.",
      url: "/our-pod-model/#we-build-your-team",
    },
    {
      imageUrl: SecondImg,
      imageUrlHover: SecondImgHover,
      step: 2,
      title: "We handle your operations",
      subtitle:
        "We establish your exclusive workspace in the UK and India and handle all logistical aspects, allowing you to concentrate on your core business.",
      url: "/our-pod-model/#we-handle-your-operation",
    },
    {
      imageUrl: ThirdImg,
      imageUrlHover: ThirdImgHover,
      step: 3,
      title: "You grow without limits",
      subtitle:
        "Collaborate seamlessly with our UK team, mirroring your on-site operations at your location or at a nearby site to meet your delivery goals.",
      url: "/our-pod-model/#you-grow-without-limits",
    },
  ];
  return (
    <div className={`our-modal-wrap ${wrapClassName}`}>
      <div className="body-container our-modal-container">
        <RevealAnimation
          component={"h2"}
          animation="slide-left"
          className="title-header mt-15 title-underline"
        >
          Our Pod Model
        </RevealAnimation>
        <div className="our-modal-cards-wrap">
          {listObject.map((item, index) => (
            <div
              className="our-modal-card"
              key={index}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className="our-modal-card-img">
                <img
                  src={
                    hoveredCard === index ? item.imageUrlHover : item.imageUrl
                  }
                  alt=""
                  className=""
                />
              </div>
              <div
                className={`our-modal-card-step ${
                  hoveredCard === index ? "active-step" : ""
                }`}
              >
                {item.step}
              </div>
              <div className="our-modal-card-content">
                <h2 className="our-modal-card-title">{item.title}</h2>
                <p className="our-modal-card-subtitle">{item.subtitle}</p>
                <div className="our-modal-card-btn">
                  <Link
                    className={`btnHero ${
                      hoveredCard === index ? "active-step" : ""
                    }`}
                    to={item.url}
                  >
                    Learn more <KeyboardArrowRightOutlinedIcon />{" "}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="progress-wrap">
        <ProgressBar />
      </div>
    </div>
  );
};

export default OurModelSection;
