import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import "./why-developer.scss";
import cardImage from "../../assets/Images/Homepage/WhyDevelopers/group_image.svg";
import arrow from "../../assets/Images/Homepage/WhyDevelopers/arrow.svg";
// import right from "../../assets/Images/Homepage/WhyDevelopers/right.svg";
import RightArrow from "../../assets/Icons/go.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import Button from "../Button";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";

function CardDetails({
  title = "",
  subTitle = "",
  subTitle2 = "",
  subTitle3 = "",
  type = "dark",
}) {
  return (
    <RevealAnimation animation="zoom-in" className={`messageCard ${type} `}>
      <p>{title}</p>
      <h3>{subTitle}</h3>
      {subTitle2 && <h3>{subTitle2}</h3>}
      <p>{subTitle3}</p>
    </RevealAnimation>
  );
}
function WhyDeveloper({
  showMoreBtn = true,
  title,
  wrapClassName = "common-bg-light",
}) {
  const [isHover, setIsHover] = useState(false);
  const { setOpenModal } = useContactModal();

  const [currentSlide, setCurrentSlide] = useState(1);
  const [showCounter, setShowCounter] = useState(true);

  const toggleHover = () => {
    setIsHover(!isHover);
  };
  const listData = [
    {
      label:
        "Average hours spent by developers on bad code, debugging, refactoring, modifying",
      left: 5.7,
      right: 17.3,
      prevLeft: 0,
      prevRight: 0,
    },
    {
      label: "Average hours spent on technical debt",
      left: 4.5,
      right: 13.5,
      prevLeft: 5.7,
      prevRight: 17.3,
    },

    {
      label: "Average hours spent on bad code",
      left: 1.3,
      right: 3.8,
      prevLeft: 4.5,
      prevRight: 13.5,
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === listData.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentSlide) {
      setShowCounter(false);
      setTimeout(() => {
        setShowCounter(true);
      }, 0);
    }
  }, [currentSlide]);

  return (
    <div className={`why-dev-warper  relative ${wrapClassName}`}>
      <div className="body-container ">
        <RevealAnimation
          component={"h2"}
          className="title-header title-underline"
        >
          {title}
        </RevealAnimation>
        <div className="d-flex relative">
          <CardDetails
            title="Economic Impact of"
            subTitle="“Good Code”"
            subTitle2="by Top 3%"
            subTitle3="British and Indian Developers"
            type="light"
          />

          <RevealAnimation animation="zoom-out" className="imageWarper">
            <img src={cardImage} alt="" />
          </RevealAnimation>
          <CardDetails
            title="Economic Impact of"
            subTitle2="“Bad Code”"
            subTitle3="by Average Developers"
          />
        </div>
        <div className="bottomArrow ">
          {listData?.map((data, index) => (
            <div
              key={index}
              className={`items ${index === currentSlide ? "active" : "hide"}`}
            >
              <RevealAnimation
                animation="slide-left"
                component={"h3"}
                className="label-title"
              >
                {data.label}
              </RevealAnimation>
              <div className=" relative bottomArrowWarper">
                <div className="leftCard">
                  <div className="content">
                    {showCounter && (
                      <>
                        <CountUp
                          start={data.prevLeft}
                          end={data.left}
                          decimals="1"
                          delay={0}
                          className="count-val"
                        />
                        <CountUp
                          start={data.prevRight}
                          end={data.right}
                          decimals="1"
                          delay={0}
                          className="count-val"
                        />
                      </>
                    )}
                  </div>
                  <img
                    src={arrow}
                    alt="center-arrow"
                    className="center-arrow"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {showMoreBtn && (
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Learn more"
                />
              }
              customClassName="info-view-resize-btn bg-white"
              color="primary"
              variant="outlined"
              label="Learn more"
              tip="Learn more"
              tooltip="no"
              onClick={() => setOpenModal(true)}
            />
          </div>
        )}
      </div>
      <div className="track_layout">
        {["", "", ""]?.map((_, inKey) => (
          <div
            key={inKey}
            className={`track ${inKey === currentSlide ? "active" : ""}`}
            onClick={() => {
              setCurrentSlide(inKey);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default WhyDeveloper;
