import React, { useEffect, useRef } from "react";
import "./Roadmap.scss";
import AwardsSvg from "../../assets/Images/Roadmap/award.svg";
import ClientSvg from "../../assets/Images/Roadmap/client.svg";
import ProjectSvg from "../../assets/Images/Roadmap/project.svg";
import RetentionSvg from "../../assets/Images/Roadmap/retention.svg";
import SatisfactionSvg from "../../assets/Images/Roadmap/satisfaction.svg";
import SolutionSvg from "../../assets/Images/Roadmap/solution.svg";

import RevealAnimation from "../RevealAnimation";

const Roadmap = () => {
  const roadmapRef = useRef(null);

  const milestones = [
    {
      icon: ProjectSvg,
      value: 119,
      text: "Projects Completed",
      color: "green",
    },
    { icon: AwardsSvg, value: 73, text: "Industry Awards", color: "teal" },
    {
      icon: ClientSvg,
      value: 87,
      text: "Clients Worldwide",
      color: "teal-dark",
    },
    {
      icon: RetentionSvg,
      value: 91,
      text: "Employee Retention Rate",
      color: "blue",
      isPercentage: true,
    },
    {
      icon: SolutionSvg,
      value: 7,
      text: "World's Top AWS Solutions Architects",
      color: "blue-dark",
      isPlus: true,
    },
    {
      icon: SatisfactionSvg,
      value: 96,
      text: "Client Satisfaction Rate",
      color: "purple",
      isPercentage: true,
    },
  ];

  useEffect(() => {
    const counters = roadmapRef.current.querySelectorAll(".counter");

    // Function to animate a single counter
    const animateCounter = (counter) => {
      const target = +counter.getAttribute("data-target");
      const speed = 10000; // Animation speed
      const increment = Math.ceil(target / speed);

      const updateCounter = () => {
        const current = +counter.innerText.replace(/\D/g, ""); // Remove non-numeric characters
        if (current < target) {
          counter.innerText = `${current + increment}${
            counter.dataset.suffix || ""
          }`;
          setTimeout(updateCounter, 30);
        } else {
          counter.innerText = `${target}${counter.dataset.suffix || ""}`;
        }
      };
      updateCounter();
    };

    // Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const counter = entry.target;
            animateCounter(counter);
            observer.unobserve(counter); // Stop observing after animation
          }
        });
      },
      { threshold: 1 } // Trigger when 50% visible
    );

    // Observe all counters
    counters.forEach((counter) => observer.observe(counter));

    return () => observer.disconnect(); // Cleanup on unmount
  }, []);

  return (
    <div className="roadmap-container" ref={roadmapRef}>
      <div className="body-container">
        <RevealAnimation
          component={"h2"}
          className="title-header mt-15 title-underline"
          animation={"slide-left"}
        >
          Digiryte's Track Record
        </RevealAnimation>
      </div>

      <div className="road-map">
        {milestones.map((milestone, index) => (
          <div className="milestone" key={index}>
            <div className="icon-container">
              <div className={`icon ${milestone.color}`}>
                <img src={milestone.icon} alt={milestone.text} />
              </div>
            </div>
            <div className="milestone-text">
              <h2
                className={`counter ${milestone.color}`}
                data-target={milestone.value}
                data-suffix={
                  milestone.isPercentage ? "%" : milestone.isPlus ? "+" : ""
                }
              >
                0
              </h2>
              <p>{milestone.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
